import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textReveal1', 'textReveal2', 'textReveal3'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const Typography = makeShortcode("Typography");
const Img = makeShortcode("Img");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ways-to-help-your-teenager-manage-anxiety"
    }}>{`Ways to help your teenager manage anxiety`}</h1>
    <TextCustom variant="h4" customColor="blue" paragraph mdxType="TextCustom">
  Fighting fear by facing fear
    </TextCustom>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} md={6} mdxType="Grid">
    <TextReveal id="textReveal1" header={<span>
          <Typography variant="h6" mdxType="Typography">
            Gently encourage your teenager to face their fears.
          </Typography>
          <TextCustom customColor="blue" variant="h6" mdxType="TextCustom">
            <i>Why?</i>
          </TextCustom>
        </span>} mdxType="TextReveal">
      <div>
        Help your teenager understand that the best way to overcome their anxiety is to face the situation that makes
        them anxious. Avoiding anxiety-provoking situations does not take the anxiety away in the long-term. In fact, it
        can increase the anxiety.
      </div>
    </TextReveal>
  </Grid>
  <Grid item xs={12} md={6} mdxType="Grid">
    <TextReveal id="textReveal2" header={<span>
          <Typography variant="h6" mdxType="Typography">
            The process of facing fears is called ‘exposure’.
          </Typography>
          <TextCustom component="span" customColor="blue" variant="h6" mdxType="TextCustom">
            <i>How does it work?</i>
          </TextCustom>
        </span>} mdxType="TextReveal">
      <p>
        Facing fears is about helping your teenager to ‘expose’ themselves to situations that make them anxious. It is
        important to start 'exposure' slowly. With your teenager, make a list of situations that make them feel nervous
        or scared. Next, arrange the list from the least scary to the most. Start with one of the least scary situations
        and encourage your teenager to stay in the situation until their anxiety reduces. Repeat several times until the
        anxiety is almost gone and your teenager is feeling more confident about the situation. Then, move on to the
        next thing on the list.
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} md={6} mdxType="Grid">
    <TextReveal id="textReveal3" header={<span>
          <Typography variant='h6' mdxType="Typography">
            Encourage your teenager to face their fears, but don’t push them.
          </Typography>
          <TextCustom component="span" customColor="blue" variant="h6" mdxType="TextCustom">
            <i>How?</i>
          </TextCustom>
        </span>} mdxType="TextReveal">
      <p>
        Facing fears is scary. Pushing your teenager to face situations they don't feel ready for can make them more
        anxious. Instead, acknowledge how hard it is to face fears and encourage them to believe that they can face
        their fears one step at a time, and that they have your full support. Be confident that your teenager is capable
        of handling a situation before encouraging them to face it. For example, it may not be helpful to encourage your
        teenager to enter a singing contest if they are not yet comfortable singing in front of the family.
      </p>
      <p>
        If your teenager is currently seeing a health professional to manage their clinical anxiety, check with your
        teenager to see how you can support what they are working on with their health professional (professionals
        commonly use ‘exposure’ when treating individuals with clinical anxiety).
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} md={6} mdxType="Grid">
    <Img src="/images/m9/54-01-AUS-version.svg" alt="Mother and daughter sitting on bench chatting" mdxType="Img" />
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      